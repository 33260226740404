<template>
  <div class="container">
    <ul class="table">
      <li class="thead">
        <span>姓名</span>
        <span>电话</span>
        <span>机构</span>
      </li>
      <li v-for="item in listData">
          <span>{{item.name}}</span>
        <span>{{item.mobile}}</span>
        <span>{{item.institution}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { provincePersonList } from "@/api/api";
export default {
  data() {
    return {
      listData: [],
    };
  },
  mounted() {
    let name = this.$route.query.name;
    provincePersonList({ province: name }).then((res) => {
      this.listData = res.data.data;
    });
  },
};
</script>

<style scoped>
.container {
  padding: 0.1rem 0;
}
.table {
  list-style: none;
  margin-top: 0.2rem;
}
.table li {
  padding: 7px 0.24rem;
  display: flex;
  justify-content: space-between;
}
.table .thead{
    border-bottom: 1px solid #ccc;
}
.table li span {
  font-size: 0.14rem;
  color: #333;
  text-align: center;
  flex: 1;
}
.table li span:first-child{
    text-align: left;
}
.table li span:last-child{
    text-align: right;
}
.table li:nth-child(2n){
    background: #edebee;
}
</style>